export const USER_MENU = [
    {
        name: 'PROXECTO',
        children: [
            {
                name: 'PROXECTO',
                url: '/proxecto'
            },
            {
                name: 'EQUIPO',
                url: '/equipo'
            }
        ]
    },
    {
        name: 'RESULTADOS',
        children: [
            {
                name: 'PUBLICACIÓNS',
                url: '/publicacions'
            },
            {
                name: 'COMUNICACIÓNS',
                url: '/comunicacions'
            },
            {
                name: 'ACTIVIDADES',
                url: '/actividades'
            }
        ]
    },
    {
        name: 'CONTACTO',
        url: '/contacto'
    }
];

export const USER_MENU_MOBILE = [
    {
        name: 'PROXECTO',
        children: [
            {
                name: 'PROXECTO',
                url: '/proxecto'
            },
            {
                name: 'EQUIPO',
                url: '/equipo'
            }
        ]
    },
    {
        name: 'RESULTADOS',
        children: [
            {
                name: 'PUBLICACIÓNS',
                url: '/publicacions'
            },
            {
                name: 'COMUNICACIÓNS',
                url: '/comunicacions'
            },
            {
                name: 'ACTIVIDADES',
                url: '/actividades'
            }
        ]
    },
    {
        name: 'NOVAS',
        url: '/novas'
    },
    {
        name: 'CONTACTO',
        url: '/contacto'
    },
    {
        name: 'BASE DE DATOS',
        url: '/busqueda'
    }
];
