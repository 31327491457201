import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LayoutModule} from './../shared/layout/layout.module';

import {AppHeaderComponent} from './app-header.component';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        LayoutModule,
        RouterModule
    ],
    exports: [
        AppHeaderComponent,
        LayoutModule
    ],
    declarations: [
        AppHeaderComponent
    ]
})
export class AppHeaderModule {
}
