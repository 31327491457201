import {NgModule} from '@angular/core';
import {
    NgbAlertModule,
    NgbDateAdapter,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {NgSelectConfig, NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Principal} from 'app/security/principal.service';
import {CustomFormsModule} from 'ngx-custom-validators';
import {FwFilesConfig, FwFilesModule} from 'fw-files';
import {
    FwAuthModule,
    FwCommonConfig,
    FwCommonModule,
    FwFormsConfig,
    FwFormsModule,
    FwPaginationConfig,
    FwPaginationModule,
    FwPrincipalService,
    FwServiceWorkerServiceConfig,
    NgbDateStringAdapter
} from 'fw-core';
import {AlertErrorComponent} from 'app/shared/alert-error.component';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {StaticPagesConfigService} from 'fw-staticpages';
import {SafeHtmlPipe} from './util/safe-html.pipe';
import {SearchSelectComponent} from 'app/shared/search-select/search-select.component';
import {CommonTooltipComponent} from 'app/shared/common-tooltip/common-tooltip.component';

@NgModule({
    imports: [FormsModule, CustomFormsModule, FwCommonModule, FwAuthModule, FwFilesModule,
        CommonModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, FwFormsModule,
        FwPaginationModule,
        RouterModule, NgSelectModule, NgOptionHighlightModule, NgbAlertModule, NgbModule],
    declarations: [AlertErrorComponent, SafeHtmlPipe, SearchSelectComponent, CommonTooltipComponent],
    providers: [
        {provide: NgbDateAdapter, useClass: NgbDateStringAdapter},
        {provide: FwPrincipalService, useClass: Principal}],
    exports: [FwFormsModule, CustomFormsModule, AlertErrorComponent,
        NgSelectModule, NgOptionHighlightModule, FormsModule, CommonModule, FwAuthModule, FwCommonModule,
        NgbDatepickerModule, NgbModalModule, NgbDropdownModule, NgbAlertModule, FwPaginationModule, SafeHtmlPipe, SearchSelectComponent]
})
export class SharedModule {

    constructor(private config: NgSelectConfig, private commonConfig: FwCommonConfig,
                private selectConfig: NgSelectConfig,
                private paginationConfig: FwPaginationConfig, private formsConfig: FwFormsConfig,
                private filesConfig: FwFilesConfig,
                private workerServiceConfig: FwServiceWorkerServiceConfig,
                public staticConfig: StaticPagesConfigService) {

        this.config.notFoundText = $localize`Not found`;

        this.selectConfig.notFoundText = $localize`Sen resultados`;
        this.selectConfig.typeToSearchText = $localize`Escriba para buscar`;
        this.selectConfig.loadingText = $localize`Cargando...`;
        this.selectConfig.clearAllText = $localize`Limpar`;

        this.commonConfig.i18n = {
            confirmOk: $localize`Sí`,
            confirmCancel: $localize`Non`,
            confirmTitle: $localize`Confirmación de borrado`,
            connectionErrorToastMessage: $localize`Non se puido establecer a conexión co servidor, comproba que tes acceso a internet. Se o problema persiste contacta co administrador.`
        };

        this.paginationConfig.pageSize = 20;
        this.paginationConfig.i18n = {
            showingFrom: $localize`Amosando os resultados dende`,
            showingTo: $localize`ata`,
            showingOf: $localize`de`,
            loadMore: $localize`Cargar máis`
        };

        this.formsConfig.date.showCalendarOnFocus = true;
        this.formsConfig.i18n = {
            save: $localize`Gardar`,
            accept: $localize`Aceptar`,
            edit: $localize`Editar`,
            cancel: $localize`Cancelar`,
            saveContinue: $localize`Gardar e continuar`,
            emptyList: $localize`Non hai resultados`,
            selectAllOptions: $localize`-- Selecciona un campo --`,
            validationDefault: $localize`Erro de validación: `,
            validationEmail: $localize`O email non é válido`,
            validationRequired: $localize`Campo requerido`,
            validationInvalidPattern: $localize`Patrón incorrecto`,
            validationMaxLength1: $localize`Debe ter como máximo `,
            validationMaxLength2: $localize` caracteres`,
            validationMinLength1: $localize`Debe ter como mínimo `,
            validationMinLength2: $localize` caracteres`,
            booleanNo: $localize`Non`,
            booleanYes: $localize`Sí`,
        };

        this.filesConfig.urlCreateArchivoTemporal = 'api/files/temporal';
        this.filesConfig.urlCreateArchivoTemporal = 'api/files/temporal';
        this.filesConfig.urlFicheroTemporal = 'api/files';
        this.filesConfig.urlImagenDefault = 'content/img/default.jpg';
        this.filesConfig.i18n = {
            fileSelect: $localize`Seleccionar arquivo`,
            fileNoFileSelected: $localize`Ningún arquivo seleccionado`,
            fileDownload: $localize`Descargar`,
            fileDelete: $localize`Eliminar`,
            fileLoading: $localize`Cargando...`,
            imageLoading: $localize`Cargando...`,
            imageChange: $localize`Cambiar`,
            imageCropTitle: $localize`Coloca e redimensiona a imaxe`,
            accept: $localize`Aceptar`,
            cancel: $localize`Cancelar`,
            genericError: $localize`Produciuse un erro`
        };

        this.workerServiceConfig.i18n = {
            toastHeaderText: $localize`Hai unha nova versión da aplicación.`,
            toastBodyText: $localize`¿Queres refrescar a páxina para obtela?`,
            updateLabel: $localize`¡Actualizar!`
        };

        this.staticConfig.pathPublicView = '';  // Al dejar en blanco esta propiedad se oculta el boton 'visualizar' de la administracion de paginas estaticas
        this.staticConfig.i18n = {
            visualize: $localize`Visualizar`,
            addLanguage: $localize`Engadir idioma`,
            confirmationMsg: $localize`¿Estás seguro de que queres borrar esta páxina estática?`,
            content: $localize`Contido`,
            delete: $localize`Borrar`,
            edit: $localize`Editar`,
            header: $localize`Identificador`,
            newPage: $localize`Nova páxina estática`,
            notFoundMessage: $localize`Páxina estática non atopada`,
            title: $localize`Título`,
            placeholder: $localize`Idiomas a engadir`,
            successDeletion: $localize`Borrouse a páxina de forma satisfactoria`,
            noLanguages: $localize`¡Precisase polo menos un idioma!`
        };

    }
}
