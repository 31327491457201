<app-header [fixed]="true" mobileSidebarToggler="{{account && account?.authorities.includes('ROLE_ADMIN')}}">
    <a class="pl-2 navbar-brand justify-content-start" routerLink="/">
        <img src="content/img/brand/RPTG-50x50.png" alt="RPTG" class="logo">
    </a>

    <ul class="nav navbar-nav ml-auto dropdown-user">

        <a [routerLink]="loginState" id="login" *ngIf="!account?.authorities || account?.authorities.length === 0"
           i18n>
            Login
        </a>

        <li class="nav-item dropdown" ngbDropdown placement="bottom right" *ngIf="isAuthenticated()">
            <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
               aria-expanded="false"
               ngbDropdownToggle (click)="false">
                <img [src]="getImageUrl()" class="img-avatar" alt="admin@bootstrapmaster.com"/> {{account?.login}}
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <div class="dropdown-header text-center"><strong>A miña conta</strong></div>
                <a class="dropdown-item" routerLink="/account/settings" ngbDropdownItem><i class="fas fa-user"></i>
                    <span i18n>Perfil</span></a>
                <a class="dropdown-item" routerLink="/account/password" ngbDropdownItem><i
                    class="fas fa-wrench"></i>
                    <span i18n>Cambiar contrasinal</span></a>
                <div class="divider"></div>
                <a class="dropdown-item" ngbDropdownItem href="#" (click)="logout()" id="logout" i18n><i
                    class="fas fa-lock"></i>Cerrar sesión</a>
            </div>
        </li>
    </ul>

</app-header>

<div class="app-body">
    <app-sidebar [fixed]="true" [display]="mostrarSidebar" *ngIf="account?.authorities.includes('ROLE_ADMIN')">
        <app-sidebar-nav [navItems]="navItems"></app-sidebar-nav>
        <app-sidebar-minimizer></app-sidebar-minimizer>
    </app-sidebar>

    <main class="main">
        <div class="breadcrumbs-container" *ngIf="crumbs.length > 0">
            <fw-breadcrumbs></fw-breadcrumbs>
        </div>
        <fw-router-outlet></fw-router-outlet>
    </main>
</div>

<div class="user-suplantado" *ngIf="account?.loginUserOriginal !== null">
        <span class="info-user-suplantado" i18n>
            Está a suplantar ao usuario '{{account?.login}}'
        </span>
    <a target="_self" href="api/logout/impersonate" i18n>Volver a tua conta '{{account?.loginUserOriginal}}'</a>
</div>

<footer class="app-footer">
    <span><a href="http://enxenio.es">Enxenio </a> &copy; 2022.</span>
    <div i18n class="version"> Version {{version}}</div>
</footer>
