export const MENU_FOOTER = [
    {
        name: 'AVISO LEGAL',
        url: 'legal'
    },
    {
        name: 'CONTACTO',
        url: 'contacto'
    },
    {
        name: 'ACHEGA DE INFORMACIÓN',
        url: 'achega-informacion'
    }
];
