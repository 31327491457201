export const ADMIN_MENU = [
    {
        title: true,
        name: 'Administración'
    },
    {
        name: 'Novas',
        url: '/admin/novas',
        icon: 'fas fa-newspaper'
    },
    {
        name: 'Usuarios',
        url: '/admin/usuarios',
        icon: 'fas fa-users'
    },
    {
        name: 'ESTÁTICAS',
        title: true
    },
    {
        name: 'Proxecto',
        children: [
            {
                name: 'Proxecto',
                url: '/admin/estatica/proxecto',
                icon: 'fas fa-file'
            },
            {
                name: 'Equipo',
                url: '/admin/estatica/equipo',
                icon: 'fas fa-file'
            }
        ]
    },
    {
        name: 'Resultados',
        children: [
            {
                name: 'Publicacións',
                url: '/admin/estatica/publicacions',
                icon: 'fas fa-file'
            },
            {
                name: 'Comunicacións',
                url: '/admin/estatica/comunicacions',
                icon: 'fas fa-file'
            },
            {
                name: 'Actividades',
                url: '/admin/estatica/actividades',
                icon: 'fas fa-file'
            }
        ]
    },
    {
        name: 'Contacto',
        url: '/admin/estatica/contacto',
        icon: 'fas fa-file'
    },
    {
        name: 'Aviso legal',
        url: '/admin/estatica/legal',
        icon: 'fas fa-file'
    },
    {
        name: 'Achega de información',
        url: '/admin/estatica/achega-informacion',
        icon: 'fas fa-file'
    },
    {
        name: 'BASE DE DATOS',
        title: true
    },
    {
        name: 'Persoas',
        url: '/admin/persoas',
        icon: 'fas fa-users'
    },
    {
        name: 'Edicións',
        url: '/admin/edicions',
        icon: 'fas fa-book-open'
    },
    {
        name: 'Entidades',
        url: '/admin/entidades',
        icon: 'fas fa-building'
    },
    {
        name: 'Textos dramáticos',
        url: '/admin/textos-dramaticos',
        icon: 'fas fa-scroll'
    },
    {
        name: 'Producións escénicas',
        url: '/admin/producions',
        icon: 'fas fa-theater-masks'
    },
    {
        name: 'Documentos',
        url: '/admin/documentos',
        icon: 'fas fa-book'
    }
];
