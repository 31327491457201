import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {URL_AVATAR_DEFAULT} from 'app/constants';
import {Principal} from 'app/security/principal.service';
import {AccountDTO, UserResourceEndpoint} from 'app/apina/apina-api';
import {Router} from '@angular/router';
import {LoginService} from 'app/auth/login/login.service';
import {USER_MENU, USER_MENU_MOBILE} from 'app/layout/menu/menu-user';
import {ResponsiveService} from 'app/shared/responsive.service';
import {MENU_FOOTER} from 'app/layout/menu/menu-footer';

@Component({
    selector: 'enxe-public-layout',
    templateUrl: './public-layout.component.html',
    styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent implements OnInit {
    mostrarSidebar: any = false;

    account: AccountDTO;
    publicNavItemsMobile = USER_MENU_MOBILE;
    publicNavItems = USER_MENU;
    footerNavItems = MENU_FOOTER;
    hiddenSidebar = true;

    constructor(@Inject(LOCALE_ID) protected localeId: string,
                private principal: Principal, private router: Router,
                private userResource: UserResourceEndpoint,
                private loginService: LoginService,
                public responsiveService: ResponsiveService) {
    }

    ngOnInit(): void {
        this.principal.state$.subscribe(datos => {
            if (datos) {
                this.account = datos;
                this.mostrarSidebar = 'lg';
            } else {
                this.mostrarSidebar = false;
            }
        });
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }

    getImageUrl() {
        return this.isAuthenticated() && this.account.avatar && this.account.avatar.id ?
            this.userResource.getAvatarUrl(this.account.login, this.account.avatar.id.toString()) : URL_AVATAR_DEFAULT;
    }

    logout() {
        this.loginService.logout().subscribe(() => {
            this.router.navigate(['']);
        });
    }

    toggleSidebar($event) {
        $event.preventDefault();
        this.hiddenSidebar = !this.hiddenSidebar;
    }

    globalToggle($event) {
        $event.preventDefault();
        if (!this.hiddenSidebar) {
            this.hiddenSidebar = true;
        }
    }

}
