<header class="public-header container-fluid mt-xl-5">
    <nav class="navbar navbar-expand-xl justify-content-between">

        <div *ngIf="!responsiveService.isMobileOrTablet(); else MobileBrand">
            <a class="navbar-brand d-flex flex-row" routerLink="/">
                <img src="content/img/brand/RPTG-100x100.png" class="logo" alt="RPTG">
                <div class="d-flex flex-column titulo-brand pl-3">
                    <span>Recuperación</span>
                    <span>do patrimonio teatral</span>
                    <span>da Galiza</span>
                    <span>(1936-1973)</span>
                </div>
            </a>
        </div>

        <ng-template #MobileBrand>
            <a class="navbar-brand d-flex flex-row" routerLink="/">
                <img src="content/img/brand/RPTG-100x100.png" alt="RPTG" class="logo">
            </a>
        </ng-template>


        <div class="collapse navbar-collapse fixed-custom-width w-100 position-relative ml-3" id="publicMenu">
            <ul class="navbar-nav">

                <!--Menu especificado en menu-user.ts-->
                <li *ngFor="let item of publicNavItems" class="nav-item d-flex align-items-center">
                    <div *ngIf="item.children; else EntradaNormal">
                        <div class="entrada-menu-container" routerLinkActive="active" ngbDropdown>
                            <a class="nav-link entrada-menu" data-toggle="dropdown"
                               role="button" ngbDropdownToggle>
                                {{item.name}}
                            </a>
                            <div class="dropdown-menu custom-dropdown" ngbDropdownMenu>
                                <div *ngFor="let subitem of item.children"
                                     class="d-flex justify-content-center subitem" routerLinkActive="active"
                                     [routerLinkActiveOptions]="{ exact:true }">
                                    <a ngbDropdownItem class="nav-link entrada-menu"
                                       [routerLink]="subitem.url">
                                        {{subitem.name}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ng-template #EntradaNormal>
                        <div class="entrada-menu-container" routerLinkActive="active"
                             [routerLinkActiveOptions]="{ exact: true }">
                            <a class="nav-link entrada-menu"
                               [routerLink]="[item.url]">
                                {{item.name}}
                            </a>
                        </div>
                    </ng-template>
                </li>

                <!--Menu de usuario/ adminitración-->
                <li class="nav-item dropdown user-menu" ngbDropdown placement="bottom right" *ngIf="isAuthenticated()">
                    <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
                       aria-expanded="false"
                       ngbDropdownToggle (click)="false">
                        <img [src]="getImageUrl()" class="img-avatar"
                             [alt]="account.login"/>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <div class="dropdown-header text-center"><strong>A miña conta</strong></div>
                        <a class="dropdown-item" routerLink="/admin" ngbDropdownItem><i class="fas fa-cog"></i>
                            <span i18n>Administración</span></a>
                        <a class="dropdown-item" routerLink="/account/settings" ngbDropdownItem><i
                            class="fas fa-user"></i>
                            <span i18n>Perfil</span></a>
                        <a class="dropdown-item" routerLink="/account/password" ngbDropdownItem><i
                            class="fas fa-wrench"></i>
                            <span i18n>Cambiar contrasinal</span></a>
                        <div class="divider"></div>
                        <a class="dropdown-item" ngbDropdownItem href="#" (click)="logout()" id="logout" i18n><i
                            class="fas fa-lock"></i>Cerrar sesión</a>
                    </div>
                </li>
            </ul>
        </div>
        <button class="navbar-toggler fixed-custom-magin" type="button" data-toggle="collapse"
                data-target="#navbarToggleExternalContent"
                (click)="toggleSidebar($event)"
                aria-controls="navbarToggleExternalContent" aria-expanded="false"
                aria-label="Toggle navigation">
            <span class="hamburger fas fa-bars"></span>
        </button>
    </nav>
</header>

<app-sidebar class="public-nav" [fixed]="true" [display]="mostrarSidebar"
             [class.hidden]="hiddenSidebar"
             [class.visible]="!hiddenSidebar">

    <span class="cross" (click)="toggleSidebar($event)">&#10005;</span>

    <app-sidebar-nav class="margin-top-nav" (selectItem)="toggleSidebar($event)"
                     [navItems]="publicNavItemsMobile"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
</app-sidebar>


<div class="app-body container-fluid mt-xl-5">
    <!--Contenido principal-->
    <main class="public-main" (click)="!hiddenSidebar ? toggleSidebar($event) : null">
        <fw-router-outlet></fw-router-outlet>
    </main>
</div>

<footer class="public-footer mt-1" (click)="!hiddenSidebar ? toggleSidebar($event) : null">
    <div class="footer container-fluid d-flex flex-row flex-wrap">

        <div class="navbar footer-navbar">
            <ul class="navbar-nav d-flex flex-row justify-content-center align-items-center flex-wrap">
                <li class="pt-3 pl-3 pl-md-0 pb-3 pr-3">
                    <span><a href="http://enxenio.es">Enxenio </a> &copy;2022</span>
                </li>
                <!--Menu especificado en menu-footer.ts-->
                <li *ngFor="let item of footerNavItems" class="nav-item p-3">
                    <a [routerLink]="[item.url]">
                        {{item.name}}
                    </a>
                </li>
            </ul>
        </div>

        <div class="d-flex align-items-center justify-content-center flex-wrap logos-footer-container">
            <a href="https://illa.udc.gal/" target="_blank" class="p-3">
                <img src="content/img/footer/LOGO_ILLA_50x50.png" alt="ILLA">
            </a>
            <a href="https://www.udc.es/es/" target="_blank" class="p-3">
                <img src="content/img/footer/LOGO_UDC.png" alt="Universidade da Coruña">
            </a>
            <a href="https://www.ciencia.gob.es/" target="_blank" class="p-3">
                <img src="content/img/footer/logo_mci.png" alt="Ministerio de ciencia e innovación">
            </a>
            <a href="https://www.xunta.gal/portada" target="_blank" class="pt-3 pl-3 pb-3 pr-0">
                <img src="content/img/footer/LOGO_XUNTA_XACOBEO.png" alt="Xunta de Galicia">
            </a>
        </div>
    </div>
</footer>
