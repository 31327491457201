/* tslint:disable */
import { Page, Pageable, Slice, Sort } from 'fw-core';

import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export interface Dictionary<V> {
    [key: string]: V;
}

export type Instant = {};

export enum EntidadEnum { PERSOA = "PERSOA", ENTIDADE = "ENTIDADE", PRODUCION = "PRODUCION", TEXTO = "TEXTO" }
export enum TipoDocumento { PROGRAMA = "PROGRAMA", CARTAZ = "CARTAZ", FOTOGRAFIAS = "FOTOGRAFIAS", IMPRENSA = "IMPRENSA", CAPA = "CAPA", TEXTOS_EN_LINA = "TEXTOS_EN_LINA", OUTROS = "OUTROS" }
export enum TipoEdicion { LIBRO = "LIBRO", REVISTA = "REVISTA" }
export enum TipoEntidade { COLECTIVO_DRAMATICO = "COLECTIVO_DRAMATICO", MOSTRA_FESTIVAL = "MOSTRA_FESTIVAL", SALA_TEATRO = "SALA_TEATRO", EDITORA = "EDITORA" }

export interface AccountDTO {
    authorities?: string[];
    avatar?: FwFileDTO;
    avatarUrl?: string;
    email?: string;
    firstName?: string;
    langKey?: string;
    lastName?: string;
    login?: string;
    loginUserOriginal?: string;
    validated?: boolean;
    version?: number;
}

export interface ActividadeProducionDTO {
    id?: number;
    persoa?: BasicDTO;
    tipoActividade?: string;
}

export interface AuditDTO {
    createdBy?: string;
    createdDate?: Instant;
    lastModifiedBy?: string;
    lastModifiedDate?: Instant;
}

export interface BasicDTO {
    id?: number;
    label?: string;
    version?: number;
}

export interface BuscadorFilter {
    entidad?: EntidadEnum;
    nome?: string;
}

export interface DocumentoDTO {
    autoria?: string;
    edicions?: BasicDTO[];
    entidades?: BasicDTO[];
    file?: FwFileDTO;
    id?: number;
    informacionAsociada?: string;
    notasInternas?: string;
    persoas?: BasicDTO[];
    procedencia?: string;
    producions?: BasicDTO[];
    publicacions?: BasicDTO[];
    tipoDocumento?: string;
    titulo?: string;
    url?: string;
}

export interface DocumentoFilter {
    autoria?: string;
    tipoDocumento?: TipoDocumento;
    titulo?: string;
}

export interface DocumentoListDTO {
    autoria?: string;
    id?: number;
    tipoDocumento?: string;
    titulo?: string;
}

export interface DocumentoPublicDTO {
    autoria?: string;
    edicions?: EdicionPublicListDTO[];
    entidades?: BasicDTO[];
    file?: FwFileDTO;
    id?: number;
    informacionAsociada?: string;
    persoas?: BasicDTO[];
    procedencia?: string;
    producions?: BasicDTO[];
    publicacions?: BasicDTO[];
    tipoDocumento?: string;
    titulo?: string;
    url?: string;
}

export interface DocumentoPublicFilter {
    entidadId?: number;
    tipoDocumento?: string;
    tipoEntidad?: string;
}

export interface DocumentoPublicListDTO {
    autoria?: string;
    id?: number;
    tipoDocumento?: string;
    titulo?: string;
}

export interface EdicionDTO {
    ano?: string;
    editora?: BasicDTO;
    id?: number;
    infoAdicional?: string;
    lugar?: string;
    nome?: string;
    notasInternas?: string;
    numeroRevista?: string;
    paxinasRevista?: string;
    publicacions?: BasicDTO[];
    tipoEdicion?: TipoEdicion;
    tituloRevista?: string;
}

export interface EdicionFilter {
    anoEdicion?: string;
    editora?: BasicDTO;
    nome?: string;
    tipoEdicion?: TipoEdicion;
}

export interface EdicionListDTO {
    ano?: string;
    editora?: BasicDTO;
    id?: number;
    nome?: string;
    tipoEdicion?: string;
}

export interface EdicionPublicDTO {
    ano?: string;
    editora?: BasicDTO;
    hasDocumentos?: boolean;
    id?: number;
    infoAdicional?: string;
    lugar?: string;
    nome?: string;
    numeroRevista?: string;
    paxinasRevista?: string;
    tipoEdicion?: string;
    tituloRevista?: string;
}

export interface EdicionPublicListDTO {
    ano?: string;
    editora?: BasicDTO;
    id?: number;
    lugar?: string;
    nome?: string;
    numeroRevista?: string;
    paxinasRevista?: string;
    tipoEdicion?: string;
    tituloRevista?: string;
}

export interface EntidadeDTO {
    id?: number;
    infoAdicional?: string;
    localidade?: string;
    nome?: string;
    notasInternas?: string;
    persoas?: BasicDTO[];
    tipoEntidade?: TipoEntidade;
}

export interface EntidadeFilter {
    nome?: string;
    tipoEntidade?: TipoEntidade;
}

export interface EntidadeListDTO {
    id?: number;
    nome?: string;
    tipoEntidade?: string;
}

export interface EntidadePublicDTO {
    allProducions?: BasicDTO[];
    anoFin?: string;
    anoInicio?: string;
    edicions?: BasicDTO[];
    hasDocumentos?: boolean;
    id?: number;
    infoAdicional?: string;
    localidade?: string;
    nome?: string;
    persoas?: BasicDTO[];
    tipoEntidade?: string;
}

export interface FwFileDTO {
    id?: number;
    name?: string;
    temporalFileName?: string;
}

export interface KeyAndPasswordDTO {
    key?: string;
    newPassword?: string;
}

export interface LanguageDTO {
    code?: string;
    default?: boolean;
    name?: string;
    translation?: string;
}

export interface NovaDTO {
    contido?: string;
    id?: number;
    imaxe?: FwFileDTO;
    titulo?: string;
}

export interface NovaFilter {
    creationInstantFrom?: Instant;
    creationInstantTo?: Instant;
    titulo?: string;
}

export interface NovaListDTO {
    createdDate?: Instant;
    id?: number;
    titulo?: string;
}

export interface NovaPulicListDTO {
    contido?: string;
    createdDate?: Instant;
    id?: number;
    imaxe?: string;
    titulo?: string;
}

export interface PasswordChangeDTO {
    currentPassword?: string;
    newPassword?: string;
}

export interface PersoaBasicDTO {
    id?: number;
    label?: string;
}

export interface PersoaDTO {
    anoMorte?: string;
    anoNacemento?: string;
    apodo?: string;
    id?: number;
    infoAdicional?: string;
    lugarMorte?: string;
    lugarNacemento?: string;
    nomeCompleto?: string;
    notasInternas?: string;
}

export interface PersoaFilter {
    nomeOuApodo?: string;
}

export interface PersoaListDTO {
    anoNacemento?: string;
    apodo?: string;
    id?: number;
    nomeCompleto?: string;
}

export interface ProducionDTO {
    actividades?: ActividadeProducionDTO[];
    ano?: string;
    colectivoDramatico?: BasicDTO[];
    edicions?: BasicDTO[];
    follaArtistica?: string;
    id?: number;
    localidade?: string;
    mostrasFestival?: BasicDTO[];
    nome?: string;
    notasInternas?: string;
    otraInformacion?: string;
    salaTeatro?: BasicDTO[];
}

export interface ProducionFilter {
    ano?: string;
    nome?: string;
}

export interface ProducionListDTO {
    ano?: string;
    colectivoDramatico?: BasicDTO;
    id?: number;
    nome?: string;
}

export interface ProducionPublicDTO {
    ano?: string;
    autores?: BasicDTO[];
    colectivoDramatico?: BasicDTO[];
    edicions?: BasicDTO[];
    follaArtistica?: string;
    hasDocumentos?: boolean;
    id?: number;
    labelFromEdicions?: string;
    localidade?: string;
    mostrasFestival?: BasicDTO[];
    nome?: string;
    otraInformacion?: string;
    salaTeatro?: BasicDTO[];
}

export interface PublicPersoaDTO {
    actividadesNormalizadas?: string[];
    anoMorte?: string;
    anoNacemento?: string;
    apodo?: string;
    entidades?: BasicDTO[];
    hasDocumentos?: boolean;
    id?: number;
    infoAdicional?: string;
    lugarMorte?: string;
    lugarNacemento?: string;
    nomeCompleto?: string;
    producions?: BasicDTO[];
    publicacions?: BasicDTO[];
    traduccions?: BasicDTO[];
}

export interface PublicacionDTO {
    autorias?: BasicDTO[];
    id?: number;
    infoAdicional?: string;
    nome?: string;
    notasInternas?: string;
    traductores?: BasicDTO[];
}

export interface PublicacionFilter {
    nome?: string;
}

export interface PublicacionListDTO {
    id?: number;
    nome?: string;
}

export interface PublicacionPublicDTO {
    autorias?: BasicDTO[];
    edicions?: EdicionPublicListDTO[];
    hasDocumentos?: boolean;
    id?: number;
    infoAdicional?: string;
    nome?: string;
    representacions?: BasicDTO[];
    traductores?: BasicDTO[];
}

export interface SearchResultDTO {
    etiquetasSearchResult?: string[];
    id?: number;
    infoAdicionalSearchResult?: string;
    localidadeSearchResult?: string;
    subtituloSearchResult?: string;
    tituloSearchResult?: string;
}

export interface StaticDTO {
    descriptions?: StaticI18nListDTO[];
    id?: number;
    name?: string;
}

export interface StaticFilter {
    name?: string;
}

export interface StaticI18nDTO {
    content?: string;
    id?: number;
    language?: LanguageDTO;
    name?: string;
    title?: string;
}

export interface StaticI18nListDTO {
    content?: string;
    id?: number;
    language?: LanguageDTO;
    title?: string;
}

export interface StaticListDTO {
    id?: number;
    languages?: LanguageDTO[];
    name?: string;
}

export interface UserBasicDTO {
    id?: number;
    label?: string;
    login?: string;
    version?: number;
}

export interface UserFilter {
    creationInstantFrom?: Instant;
    creationInstantTo?: Instant;
    login?: string;
    role?: string;
    searchTerm?: string;
}

export interface UserFormDTO {
    audit?: AuditDTO;
    authorities?: string[];
    avatar?: FwFileDTO;
    blocked?: boolean;
    email?: string;
    firstName?: string;
    id?: number;
    imageUrl?: string;
    langKey?: string;
    lastName?: string;
    login?: string;
    resetKey?: string;
    resetLink?: string;
    validated?: boolean;
    validationKey?: string;
    validationLink?: string;
    version?: number;
}

export interface UserFormNewDTO {
    authorities?: string[];
    email?: string;
    firstName?: string;
    imageUrl?: string;
    lastName?: string;
    login?: string;
    password?: string;
    validated?: boolean;
}

export interface UserListDTO {
    audit?: AuditDTO;
    authorities?: string[];
    blocked?: boolean;
    email?: string;
    id?: number;
    imageUrl?: string;
    lastLogin?: Instant;
    login?: string;
    validated?: boolean;
}

export function registerDefaultSerializers(config: ApinaConfig) {
    config.registerIdentitySerializer('Instant');

    config.registerIdentitySerializer('EntidadEnum');
    config.registerIdentitySerializer('TipoDocumento');
    config.registerIdentitySerializer('TipoEdicion');
    config.registerIdentitySerializer('TipoEntidade');

    config.registerClassSerializer('AccountDTO', {
        'authorities': 'string[]',
        'avatar': 'FwFileDTO',
        'avatarUrl': 'string',
        'email': 'string',
        'firstName': 'string',
        'langKey': 'string',
        'lastName': 'string',
        'login': 'string',
        'loginUserOriginal': 'string',
        'validated': 'boolean',
        'version': 'number'
    });

    config.registerClassSerializer('ActividadeProducionDTO', {
        'id': 'number',
        'persoa': 'BasicDTO',
        'tipoActividade': 'string'
    });

    config.registerClassSerializer('AuditDTO', {
        'createdBy': 'string',
        'createdDate': 'Instant',
        'lastModifiedBy': 'string',
        'lastModifiedDate': 'Instant'
    });

    config.registerClassSerializer('BasicDTO', {
        'id': 'number',
        'label': 'string',
        'version': 'number'
    });

    config.registerClassSerializer('BuscadorFilter', {
        'entidad': 'EntidadEnum',
        'nome': 'string'
    });

    config.registerClassSerializer('DocumentoDTO', {
        'autoria': 'string',
        'edicions': 'BasicDTO[]',
        'entidades': 'BasicDTO[]',
        'file': 'FwFileDTO',
        'id': 'number',
        'informacionAsociada': 'string',
        'notasInternas': 'string',
        'persoas': 'BasicDTO[]',
        'procedencia': 'string',
        'producions': 'BasicDTO[]',
        'publicacions': 'BasicDTO[]',
        'tipoDocumento': 'string',
        'titulo': 'string',
        'url': 'string'
    });

    config.registerClassSerializer('DocumentoFilter', {
        'autoria': 'string',
        'tipoDocumento': 'TipoDocumento',
        'titulo': 'string'
    });

    config.registerClassSerializer('DocumentoListDTO', {
        'autoria': 'string',
        'id': 'number',
        'tipoDocumento': 'string',
        'titulo': 'string'
    });

    config.registerClassSerializer('DocumentoPublicDTO', {
        'autoria': 'string',
        'edicions': 'EdicionPublicListDTO[]',
        'entidades': 'BasicDTO[]',
        'file': 'FwFileDTO',
        'id': 'number',
        'informacionAsociada': 'string',
        'persoas': 'BasicDTO[]',
        'procedencia': 'string',
        'producions': 'BasicDTO[]',
        'publicacions': 'BasicDTO[]',
        'tipoDocumento': 'string',
        'titulo': 'string',
        'url': 'string'
    });

    config.registerClassSerializer('DocumentoPublicFilter', {
        'entidadId': 'number',
        'tipoDocumento': 'string',
        'tipoEntidad': 'string'
    });

    config.registerClassSerializer('DocumentoPublicListDTO', {
        'autoria': 'string',
        'id': 'number',
        'tipoDocumento': 'string',
        'titulo': 'string'
    });

    config.registerClassSerializer('EdicionDTO', {
        'ano': 'string',
        'editora': 'BasicDTO',
        'id': 'number',
        'infoAdicional': 'string',
        'lugar': 'string',
        'nome': 'string',
        'notasInternas': 'string',
        'numeroRevista': 'string',
        'paxinasRevista': 'string',
        'publicacions': 'BasicDTO[]',
        'tipoEdicion': 'TipoEdicion',
        'tituloRevista': 'string'
    });

    config.registerClassSerializer('EdicionFilter', {
        'anoEdicion': 'string',
        'editora': 'BasicDTO',
        'nome': 'string',
        'tipoEdicion': 'TipoEdicion'
    });

    config.registerClassSerializer('EdicionListDTO', {
        'ano': 'string',
        'editora': 'BasicDTO',
        'id': 'number',
        'nome': 'string',
        'tipoEdicion': 'string'
    });

    config.registerClassSerializer('EdicionPublicDTO', {
        'ano': 'string',
        'editora': 'BasicDTO',
        'hasDocumentos': 'boolean',
        'id': 'number',
        'infoAdicional': 'string',
        'lugar': 'string',
        'nome': 'string',
        'numeroRevista': 'string',
        'paxinasRevista': 'string',
        'tipoEdicion': 'string',
        'tituloRevista': 'string'
    });

    config.registerClassSerializer('EdicionPublicListDTO', {
        'ano': 'string',
        'editora': 'BasicDTO',
        'id': 'number',
        'lugar': 'string',
        'nome': 'string',
        'numeroRevista': 'string',
        'paxinasRevista': 'string',
        'tipoEdicion': 'string',
        'tituloRevista': 'string'
    });

    config.registerClassSerializer('EntidadeDTO', {
        'id': 'number',
        'infoAdicional': 'string',
        'localidade': 'string',
        'nome': 'string',
        'notasInternas': 'string',
        'persoas': 'BasicDTO[]',
        'tipoEntidade': 'TipoEntidade'
    });

    config.registerClassSerializer('EntidadeFilter', {
        'nome': 'string',
        'tipoEntidade': 'TipoEntidade'
    });

    config.registerClassSerializer('EntidadeListDTO', {
        'id': 'number',
        'nome': 'string',
        'tipoEntidade': 'string'
    });

    config.registerClassSerializer('EntidadePublicDTO', {
        'allProducions': 'BasicDTO[]',
        'anoFin': 'string',
        'anoInicio': 'string',
        'edicions': 'BasicDTO[]',
        'hasDocumentos': 'boolean',
        'id': 'number',
        'infoAdicional': 'string',
        'localidade': 'string',
        'nome': 'string',
        'persoas': 'BasicDTO[]',
        'tipoEntidade': 'string'
    });

    config.registerClassSerializer('FwFileDTO', {
        'id': 'number',
        'name': 'string',
        'temporalFileName': 'string'
    });

    config.registerClassSerializer('KeyAndPasswordDTO', {
        'key': 'string',
        'newPassword': 'string'
    });

    config.registerClassSerializer('LanguageDTO', {
        'code': 'string',
        'default': 'boolean',
        'name': 'string',
        'translation': 'string'
    });

    config.registerClassSerializer('NovaDTO', {
        'contido': 'string',
        'id': 'number',
        'imaxe': 'FwFileDTO',
        'titulo': 'string'
    });

    config.registerClassSerializer('NovaFilter', {
        'creationInstantFrom': 'Instant',
        'creationInstantTo': 'Instant',
        'titulo': 'string'
    });

    config.registerClassSerializer('NovaListDTO', {
        'createdDate': 'Instant',
        'id': 'number',
        'titulo': 'string'
    });

    config.registerClassSerializer('NovaPulicListDTO', {
        'contido': 'string',
        'createdDate': 'Instant',
        'id': 'number',
        'imaxe': 'string',
        'titulo': 'string'
    });

    config.registerClassSerializer('PasswordChangeDTO', {
        'currentPassword': 'string',
        'newPassword': 'string'
    });

    config.registerClassSerializer('PersoaBasicDTO', {
        'id': 'number',
        'label': 'string'
    });

    config.registerClassSerializer('PersoaDTO', {
        'anoMorte': 'string',
        'anoNacemento': 'string',
        'apodo': 'string',
        'id': 'number',
        'infoAdicional': 'string',
        'lugarMorte': 'string',
        'lugarNacemento': 'string',
        'nomeCompleto': 'string',
        'notasInternas': 'string'
    });

    config.registerClassSerializer('PersoaFilter', {
        'nomeOuApodo': 'string'
    });

    config.registerClassSerializer('PersoaListDTO', {
        'anoNacemento': 'string',
        'apodo': 'string',
        'id': 'number',
        'nomeCompleto': 'string'
    });

    config.registerClassSerializer('ProducionDTO', {
        'actividades': 'ActividadeProducionDTO[]',
        'ano': 'string',
        'colectivoDramatico': 'BasicDTO[]',
        'edicions': 'BasicDTO[]',
        'follaArtistica': 'string',
        'id': 'number',
        'localidade': 'string',
        'mostrasFestival': 'BasicDTO[]',
        'nome': 'string',
        'notasInternas': 'string',
        'otraInformacion': 'string',
        'salaTeatro': 'BasicDTO[]'
    });

    config.registerClassSerializer('ProducionFilter', {
        'ano': 'string',
        'nome': 'string'
    });

    config.registerClassSerializer('ProducionListDTO', {
        'ano': 'string',
        'colectivoDramatico': 'BasicDTO',
        'id': 'number',
        'nome': 'string'
    });

    config.registerClassSerializer('ProducionPublicDTO', {
        'ano': 'string',
        'autores': 'BasicDTO[]',
        'colectivoDramatico': 'BasicDTO[]',
        'edicions': 'BasicDTO[]',
        'follaArtistica': 'string',
        'hasDocumentos': 'boolean',
        'id': 'number',
        'labelFromEdicions': 'string',
        'localidade': 'string',
        'mostrasFestival': 'BasicDTO[]',
        'nome': 'string',
        'otraInformacion': 'string',
        'salaTeatro': 'BasicDTO[]'
    });

    config.registerClassSerializer('PublicPersoaDTO', {
        'actividadesNormalizadas': 'string[]',
        'anoMorte': 'string',
        'anoNacemento': 'string',
        'apodo': 'string',
        'entidades': 'BasicDTO[]',
        'hasDocumentos': 'boolean',
        'id': 'number',
        'infoAdicional': 'string',
        'lugarMorte': 'string',
        'lugarNacemento': 'string',
        'nomeCompleto': 'string',
        'producions': 'BasicDTO[]',
        'publicacions': 'BasicDTO[]',
        'traduccions': 'BasicDTO[]'
    });

    config.registerClassSerializer('PublicacionDTO', {
        'autorias': 'BasicDTO[]',
        'id': 'number',
        'infoAdicional': 'string',
        'nome': 'string',
        'notasInternas': 'string',
        'traductores': 'BasicDTO[]'
    });

    config.registerClassSerializer('PublicacionFilter', {
        'nome': 'string'
    });

    config.registerClassSerializer('PublicacionListDTO', {
        'id': 'number',
        'nome': 'string'
    });

    config.registerClassSerializer('PublicacionPublicDTO', {
        'autorias': 'BasicDTO[]',
        'edicions': 'EdicionPublicListDTO[]',
        'hasDocumentos': 'boolean',
        'id': 'number',
        'infoAdicional': 'string',
        'nome': 'string',
        'representacions': 'BasicDTO[]',
        'traductores': 'BasicDTO[]'
    });

    config.registerClassSerializer('SearchResultDTO', {
        'etiquetasSearchResult': 'string[]',
        'id': 'number',
        'infoAdicionalSearchResult': 'string',
        'localidadeSearchResult': 'string',
        'subtituloSearchResult': 'string',
        'tituloSearchResult': 'string'
    });

    config.registerClassSerializer('StaticDTO', {
        'descriptions': 'StaticI18nListDTO[]',
        'id': 'number',
        'name': 'string'
    });

    config.registerClassSerializer('StaticFilter', {
        'name': 'string'
    });

    config.registerClassSerializer('StaticI18nDTO', {
        'content': 'string',
        'id': 'number',
        'language': 'LanguageDTO',
        'name': 'string',
        'title': 'string'
    });

    config.registerClassSerializer('StaticI18nListDTO', {
        'content': 'string',
        'id': 'number',
        'language': 'LanguageDTO',
        'title': 'string'
    });

    config.registerClassSerializer('StaticListDTO', {
        'id': 'number',
        'languages': 'LanguageDTO[]',
        'name': 'string'
    });

    config.registerClassSerializer('UserBasicDTO', {
        'id': 'number',
        'label': 'string',
        'login': 'string',
        'version': 'number'
    });

    config.registerClassSerializer('UserFilter', {
        'creationInstantFrom': 'Instant',
        'creationInstantTo': 'Instant',
        'login': 'string',
        'role': 'string',
        'searchTerm': 'string'
    });

    config.registerClassSerializer('UserFormDTO', {
        'audit': 'AuditDTO',
        'authorities': 'string[]',
        'avatar': 'FwFileDTO',
        'blocked': 'boolean',
        'email': 'string',
        'firstName': 'string',
        'id': 'number',
        'imageUrl': 'string',
        'langKey': 'string',
        'lastName': 'string',
        'login': 'string',
        'resetKey': 'string',
        'resetLink': 'string',
        'validated': 'boolean',
        'validationKey': 'string',
        'validationLink': 'string',
        'version': 'number'
    });

    config.registerClassSerializer('UserFormNewDTO', {
        'authorities': 'string[]',
        'email': 'string',
        'firstName': 'string',
        'imageUrl': 'string',
        'lastName': 'string',
        'login': 'string',
        'password': 'string',
        'validated': 'boolean'
    });

    config.registerClassSerializer('UserListDTO', {
        'audit': 'AuditDTO',
        'authorities': 'string[]',
        'blocked': 'boolean',
        'email': 'string',
        'id': 'number',
        'imageUrl': 'string',
        'lastLogin': 'Instant',
        'login': 'string',
        'validated': 'boolean'
    });

}

export class ApinaConfig {

    /** Prefix added for all API calls */
    baseUrl: string = "";

    private serializers: SerializerMap = {
        any: identitySerializer,
        string: identitySerializer,
        number: identitySerializer,
        boolean: identitySerializer
    };

    constructor() {
        registerDefaultSerializers(this);
    }

    serialize(value: any, type: string): any {
        return this.lookupSerializer(type).serialize(value);
    }

    deserialize(value: any, type: string): any {
        return this.lookupSerializer(type).deserialize(value);
    }

    registerSerializer(name: string, serializer: Serializer) {
        this.serializers[name] = serializer;
    }

    registerEnumSerializer(name: string, enumObject: any) {
        this.registerSerializer(name, enumSerializer(enumObject));
    }

    registerClassSerializer(name: string, fields: any) {
        this.registerSerializer(name, this.classSerializer(fields));
    }

    registerIdentitySerializer(name: string) {
        this.registerSerializer(name, identitySerializer);
    }

    registerDiscriminatedUnionSerializer(name: string, discriminator: string, types: { [key: string]: string; }) {
        this.registerSerializer(name, this.discriminatedUnionSerializer(discriminator, types));
    }

    private classSerializer(fields: any): Serializer {
        function mapProperties(obj: any, propertyMapper: (value: any, type: string) => any) {
            if (obj === null || obj === undefined) {
                return obj;
            }

            const result: any = {};

            for (const name in fields) {
                if (fields.hasOwnProperty(name)) {
                    const value: any = obj[name];
                    const type: string = fields[name];
                    result[name] = propertyMapper(value, type);
                }
            }

            return result;
        }

        const serialize = this.serialize.bind(this);
        const deserialize = this.deserialize.bind(this);
        return {
            serialize(obj) {
                return mapProperties(obj, serialize);
            },
            deserialize(obj) {
                return mapProperties(obj, deserialize);
            }
        };
    }

    private discriminatedUnionSerializer(discriminatorProperty: string, types: { [key: string]: string; }): Serializer {
        const resolveSerializer = (localType: string) => {
            return this.lookupSerializer(types[localType]);
        };

        return {
            serialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).serialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            },
            deserialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).deserialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            }
        };
    }

    private lookupSerializer(type: string): Serializer {
        if (!type) throw new Error("no type given");

        if (type.indexOf('[]', type.length - 2) !== -1) { // type.endsWith('[]')
            const elementType = type.substring(0, type.length - 2);
            const elementSerializer = this.lookupSerializer(elementType);
            return arraySerializer(elementSerializer);
        }
        const serializer = this.serializers[type];
        if (serializer) {
            return serializer;
        } else {
            throw new Error(`could not find serializer for type '${type}'`);
        }
    }
}

function arraySerializer(elementSerializer: Serializer): Serializer {
    function safeMap(value: any[], mapper: (a: any) => any) {
        if (!value)
            return value;
        else
            return value.map(mapper);
    }

    return {
        serialize(value) {
            return safeMap(value, elementSerializer.serialize.bind(elementSerializer));
        },
        deserialize(value) {
            return safeMap(value, elementSerializer.deserialize.bind(elementSerializer));
        }
    }
}

export interface RequestData {
    uriTemplate: string;
    method: string;
    pathVariables?: any;
    requestParams?: any;
    requestBody?: any;
    responseType?: string;
}

export interface Serializer {
    serialize(o: any): any;
    deserialize(o: any): any;
}

const identitySerializer: Serializer = {
    serialize(o) {
        return o;
    },
    deserialize(o) {
        return o;
    }
};

function enumSerializer(enumObject: any): Serializer {
    return {
        serialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        },
        deserialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        }
    }
}

interface SerializerMap {
    [name: string]: Serializer;
}

export abstract class ApinaEndpointContext {

    constructor(protected config: ApinaConfig) {
    }

    abstract request(data: RequestData): Observable<any>

    serialize(value: any, type: string): any {
        return this.config.serialize(value, type);
    }

    deserialize(value: any, type: string): any {
        return this.config.deserialize(value, type);
    }

    buildUrl(uriTemplate: String, pathVariables: any): string {
        return this.config.baseUrl + uriTemplate.replace(/{([^}]+)}/g, (match, name) => pathVariables[name]);
    }
}

@Injectable()
export class DefaultApinaEndpointContext extends ApinaEndpointContext {

    constructor(private httpClient: HttpClient, config: ApinaConfig) {
        super(config);
    }

    request(data: RequestData): Observable<any> {
        const url = this.buildUrl(data.uriTemplate, data.pathVariables);

        const requestParams = data.requestParams;
        let params: HttpParams | undefined = undefined;
        if (requestParams != null) {
            const filteredParams: { [key: string]: any }  = {};
            for (const key of Object.keys(requestParams)) {
                const value = requestParams[key];
                if (value != null)
                    filteredParams[key] = value;
            }

            params = new HttpParams({fromObject: filteredParams});
        }


        return this.httpClient.request(data.method, url, { params: params, body: data.requestBody })
            .pipe(map(r => data.responseType ? this.config.deserialize(r, data.responseType) : r));
    }
}

@Injectable( {providedIn: 'root'} )
export class AccountResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    changePassword(passwordChangeDto: PasswordChangeDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/account/change-password',
            'method': 'POST',
            'requestBody': this.context.serialize(passwordChangeDto, 'PasswordChangeDTO')
        });
    }

    changePasswordUrl(passwordChangeDto: PasswordChangeDTO): string {
        return this.context.buildUrl('/account/change-password' , null);
    }

    finishPasswordReset(keyAndPassword: KeyAndPasswordDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/account/reset-password/finish',
            'method': 'POST',
            'requestBody': this.context.serialize(keyAndPassword, 'KeyAndPasswordDTO')
        });
    }

    finishPasswordResetUrl(keyAndPassword: KeyAndPasswordDTO): string {
        return this.context.buildUrl('/account/reset-password/finish' , null);
    }

    getAccount(): Observable<AccountDTO> {
        return this.context.request({
            'uriTemplate': '/account',
            'method': 'GET',
            'responseType': 'AccountDTO'
        });
    }

    getAccountUrl(): string {
        return this.context.buildUrl('/account' , null);
    }

    isAuthenticated(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/authenticate',
            'method': 'GET',
            'responseType': 'string'
        });
    }

    isAuthenticatedUrl(): string {
        return this.context.buildUrl('/authenticate' , null);
    }

    registerAccount(userFormNewDTO: UserFormNewDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/register',
            'method': 'POST',
            'requestBody': this.context.serialize(userFormNewDTO, 'UserFormNewDTO')
        });
    }

    registerAccountUrl(userFormNewDTO: UserFormNewDTO): string {
        return this.context.buildUrl('/register' , null);
    }

    requestPasswordReset(mail: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/account/reset-password/init',
            'method': 'POST',
            'requestBody': this.context.serialize(mail, 'string')
        });
    }

    requestPasswordResetUrl(mail: string): string {
        return this.context.buildUrl('/account/reset-password/init' , null);
    }

    saveAccount(accountDTO: AccountDTO): Observable<AccountDTO> {
        return this.context.request({
            'uriTemplate': '/account',
            'method': 'POST',
            'requestBody': this.context.serialize(accountDTO, 'AccountDTO'),
            'responseType': 'AccountDTO'
        });
    }

    saveAccountUrl(accountDTO: AccountDTO): string {
        return this.context.buildUrl('/account' , null);
    }

    validateAccount(key: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/validate',
            'method': 'GET',
            'requestParams': {
                'key': this.context.serialize(key, 'string')
            }
        });
    }

    validateAccountUrl(key: string): string {
        return this.context.buildUrl('/validate' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class BuscadorResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    buscar(filter: BuscadorFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/database/buscar',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'BuscadorFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    buscarUrl(filter: BuscadorFilter, page: Pageable): string {
        return this.context.buildUrl('/database/buscar' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class DocumentoResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(documentoDTO: DocumentoDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/documentos',
            'method': 'POST',
            'requestBody': this.context.serialize(documentoDTO, 'DocumentoDTO'),
            'responseType': 'number'
        });
    }

    createUrl(documentoDTO: DocumentoDTO): string {
        return this.context.buildUrl('/documentos' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/documentos/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/documentos/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: DocumentoFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/documentos',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'DocumentoFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: DocumentoFilter, page: Pageable): string {
        return this.context.buildUrl('/documentos' , null);
    }

    findAllBasic(filter: DocumentoFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/documentos/findAllBasic',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'DocumentoFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicUrl(filter: DocumentoFilter, page: Pageable): string {
        return this.context.buildUrl('/documentos/findAllBasic' , null);
    }

    findAllPublic(filter: DocumentoPublicFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/documentos/findAllPublic',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'DocumentoPublicFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllPublicUrl(filter: DocumentoPublicFilter, page: Pageable): string {
        return this.context.buildUrl('/documentos/findAllPublic' , null);
    }

    get(id: number): Observable<DocumentoDTO> {
        return this.context.request({
            'uriTemplate': '/documentos/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'DocumentoDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/documentos/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getFile(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/documentos/{id}/file',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    getFileUrl(id: number): string {
        return this.context.buildUrl('/documentos/{id}/file' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getImage(imageId: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/documentos/downloadImage/{imageId}',
            'method': 'GET',
            'pathVariables': {
                'imageId': this.context.serialize(imageId, 'number')
            }
        });
    }

    getImageUrl(imageId: number): string {
        return this.context.buildUrl('/documentos/downloadImage/{imageId}' , {
            'imageId': this.context.serialize(imageId, 'number')
        });
    }

    getImagenThumbnail(documentoId: number, dimensiones: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/documentos/{documentoId}/file/{dimensiones}/imagen',
            'method': 'GET',
            'pathVariables': {
                'documentoId': this.context.serialize(documentoId, 'number'),
                'dimensiones': this.context.serialize(dimensiones, 'string')
            }
        });
    }

    getImagenThumbnailUrl(documentoId: number, dimensiones: string): string {
        return this.context.buildUrl('/documentos/{documentoId}/file/{dimensiones}/imagen' , {
            'documentoId': this.context.serialize(documentoId, 'number'),
            'dimensiones': this.context.serialize(dimensiones, 'string')
        });
    }

    getPublic(id: number): Observable<DocumentoPublicDTO> {
        return this.context.request({
            'uriTemplate': '/documentos/getPublic/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'DocumentoPublicDTO'
        });
    }

    getPublicUrl(id: number): string {
        return this.context.buildUrl('/documentos/getPublic/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, documentoDTO: DocumentoDTO): Observable<DocumentoDTO> {
        return this.context.request({
            'uriTemplate': '/documentos/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(documentoDTO, 'DocumentoDTO'),
            'responseType': 'DocumentoDTO'
        });
    }

    updateUrl(id: number, documentoDTO: DocumentoDTO): string {
        return this.context.buildUrl('/documentos/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class EdicionResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(edicionDTO: EdicionDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/edicions',
            'method': 'POST',
            'requestBody': this.context.serialize(edicionDTO, 'EdicionDTO'),
            'responseType': 'number'
        });
    }

    createUrl(edicionDTO: EdicionDTO): string {
        return this.context.buildUrl('/edicions' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/edicions/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/edicions/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: EdicionFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/edicions',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'EdicionFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: EdicionFilter, page: Pageable): string {
        return this.context.buildUrl('/edicions' , null);
    }

    findAllBasic(filter: EdicionFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/edicions/findAllBasic',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'EdicionFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicUrl(filter: EdicionFilter, page: Pageable): string {
        return this.context.buildUrl('/edicions/findAllBasic' , null);
    }

    get(id: number): Observable<EdicionDTO> {
        return this.context.request({
            'uriTemplate': '/edicions/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'EdicionDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/edicions/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getPublic(id: number): Observable<EdicionPublicDTO> {
        return this.context.request({
            'uriTemplate': '/edicions/public/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'EdicionPublicDTO'
        });
    }

    getPublicUrl(id: number): string {
        return this.context.buildUrl('/edicions/public/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, edicionDTO: EdicionDTO): Observable<EdicionDTO> {
        return this.context.request({
            'uriTemplate': '/edicions/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(edicionDTO, 'EdicionDTO'),
            'responseType': 'EdicionDTO'
        });
    }

    updateUrl(id: number, edicionDTO: EdicionDTO): string {
        return this.context.buildUrl('/edicions/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class EntidadeResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(entidadeDTO: EntidadeDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/entidades',
            'method': 'POST',
            'requestBody': this.context.serialize(entidadeDTO, 'EntidadeDTO'),
            'responseType': 'number'
        });
    }

    createUrl(entidadeDTO: EntidadeDTO): string {
        return this.context.buildUrl('/entidades' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/entidades/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/entidades/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: EntidadeFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/entidades',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'EntidadeFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: EntidadeFilter, page: Pageable): string {
        return this.context.buildUrl('/entidades' , null);
    }

    findAllBasic(filter: EntidadeFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/entidades/findAllBasic',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'EntidadeFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicUrl(filter: EntidadeFilter, page: Pageable): string {
        return this.context.buildUrl('/entidades/findAllBasic' , null);
    }

    findAllBasicColectivoDramatico(filter: EntidadeFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/entidades/findAllBasicColDramatico',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'EntidadeFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicColectivoDramaticoUrl(filter: EntidadeFilter, page: Pageable): string {
        return this.context.buildUrl('/entidades/findAllBasicColDramatico' , null);
    }

    findAllBasicEditoras(filter: EntidadeFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/entidades/findAllBasicEditoras',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'EntidadeFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicEditorasUrl(filter: EntidadeFilter, page: Pageable): string {
        return this.context.buildUrl('/entidades/findAllBasicEditoras' , null);
    }

    findAllBasicMostras(page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/entidades/findAllBasicMostras',
            'method': 'GET',
            'requestParams': {
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicMostrasUrl(page: Pageable): string {
        return this.context.buildUrl('/entidades/findAllBasicMostras' , null);
    }

    findAllBasicSalaTeatro(filter: EntidadeFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/entidades/findAllBasicSalaTeatro',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'EntidadeFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicSalaTeatroUrl(filter: EntidadeFilter, page: Pageable): string {
        return this.context.buildUrl('/entidades/findAllBasicSalaTeatro' , null);
    }

    get(id: number): Observable<EntidadeDTO> {
        return this.context.request({
            'uriTemplate': '/entidades/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'EntidadeDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/entidades/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getPublic(id: number): Observable<EntidadePublicDTO> {
        return this.context.request({
            'uriTemplate': '/entidades/public/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'EntidadePublicDTO'
        });
    }

    getPublicUrl(id: number): string {
        return this.context.buildUrl('/entidades/public/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, entidadeDTO: EntidadeDTO): Observable<EntidadeDTO> {
        return this.context.request({
            'uriTemplate': '/entidades/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(entidadeDTO, 'EntidadeDTO'),
            'responseType': 'EntidadeDTO'
        });
    }

    updateUrl(id: number, entidadeDTO: EntidadeDTO): string {
        return this.context.buildUrl('/entidades/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class EnumResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    getEntidadEnum(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/enum/entidad-enum',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    getEntidadEnumUrl(): string {
        return this.context.buildUrl('/enum/entidad-enum' , null);
    }

    getTipoActividade(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/enum/tipo-actividade',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    getTipoActividadeUrl(): string {
        return this.context.buildUrl('/enum/tipo-actividade' , null);
    }

    getTipoDocumento(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/enum/tipo-documento',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    getTipoDocumentoUrl(): string {
        return this.context.buildUrl('/enum/tipo-documento' , null);
    }

    getTipoEdicion(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/enum/tipo-edicion',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    getTipoEdicionUrl(): string {
        return this.context.buildUrl('/enum/tipo-edicion' , null);
    }

    getTipoEntidade(): Observable<Dictionary<any>> {
        return this.context.request({
            'uriTemplate': '/enum/tipo-entidade',
            'method': 'GET',
            'responseType': 'Dictionary<any>'
        });
    }

    getTipoEntidadeUrl(): string {
        return this.context.buildUrl('/enum/tipo-entidade' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class FicheroResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    downloadTemporalFile(temporalFileName: string, fileName: string, fileCodification: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/files/{temporalFileName}',
            'method': 'GET',
            'pathVariables': {
                'temporalFileName': this.context.serialize(temporalFileName, 'string')
            },
            'requestParams': {
                'fileName': this.context.serialize(fileName, 'string'),
                'fileCodification': this.context.serialize(fileCodification, 'string')
            }
        });
    }

    downloadTemporalFileUrl(temporalFileName: string, fileName: string, fileCodification: string): string {
        return this.context.buildUrl('/files/{temporalFileName}' , {
            'temporalFileName': this.context.serialize(temporalFileName, 'string')
        });
    }

    saveTemporalFile(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/files/temporal',
            'method': 'POST',
            'responseType': 'string'
        });
    }

    saveTemporalFileUrl(): string {
        return this.context.buildUrl('/files/temporal' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class FileResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    getFile(fileId: number, prefix: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/files/persistentfiles/{prefix}/{fileId}',
            'method': 'GET',
            'pathVariables': {
                'fileId': this.context.serialize(fileId, 'number'),
                'prefix': this.context.serialize(prefix, 'string')
            }
        });
    }

    getFileUrl(fileId: number, prefix: string): string {
        return this.context.buildUrl('/files/persistentfiles/{prefix}/{fileId}' , {
            'fileId': this.context.serialize(fileId, 'number'),
            'prefix': this.context.serialize(prefix, 'string')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class LanguageResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    getCurrentLanguage(): Observable<LanguageDTO> {
        return this.context.request({
            'uriTemplate': '/languages/current',
            'method': 'GET',
            'responseType': 'LanguageDTO'
        });
    }

    getCurrentLanguageUrl(): string {
        return this.context.buildUrl('/languages/current' , null);
    }

    getLanguages(): Observable<LanguageDTO[]> {
        return this.context.request({
            'uriTemplate': '/languages',
            'method': 'GET',
            'responseType': 'LanguageDTO[]'
        });
    }

    getLanguagesUrl(): string {
        return this.context.buildUrl('/languages' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class NovaResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(novaDTO: NovaDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/novas',
            'method': 'POST',
            'requestBody': this.context.serialize(novaDTO, 'NovaDTO'),
            'responseType': 'number'
        });
    }

    createUrl(novaDTO: NovaDTO): string {
        return this.context.buildUrl('/novas' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/novas/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/novas/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: NovaFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/novas',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'NovaFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: NovaFilter, page: Pageable): string {
        return this.context.buildUrl('/novas' , null);
    }

    findAllBasic(filter: NovaFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/novas/findAllBasic',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'NovaFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicUrl(filter: NovaFilter, page: Pageable): string {
        return this.context.buildUrl('/novas/findAllBasic' , null);
    }

    findAllPublic(filter: NovaFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/novas/findAllPublic',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'NovaFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllPublicUrl(filter: NovaFilter, page: Pageable): string {
        return this.context.buildUrl('/novas/findAllPublic' , null);
    }

    get(id: number): Observable<NovaDTO> {
        return this.context.request({
            'uriTemplate': '/novas/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'NovaDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/novas/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getImagenThumbnail(id: number, dimensiones: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/novas/{id}/{dimensiones}/imagen',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number'),
                'dimensiones': this.context.serialize(dimensiones, 'string')
            }
        });
    }

    getImagenThumbnailUrl(id: number, dimensiones: string): string {
        return this.context.buildUrl('/novas/{id}/{dimensiones}/imagen' , {
            'id': this.context.serialize(id, 'number'),
            'dimensiones': this.context.serialize(dimensiones, 'string')
        });
    }

    getImaxeNova(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/novas/{id}/imaxe',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    getImaxeNovaUrl(id: number): string {
        return this.context.buildUrl('/novas/{id}/imaxe' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, novaDTO: NovaDTO): Observable<NovaDTO> {
        return this.context.request({
            'uriTemplate': '/novas/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(novaDTO, 'NovaDTO'),
            'responseType': 'NovaDTO'
        });
    }

    updateUrl(id: number, novaDTO: NovaDTO): string {
        return this.context.buildUrl('/novas/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class PersoaResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    checkNameDuplicity(nomePersoa: string, apodo: string): Observable<PersoaBasicDTO[]> {
        return this.context.request({
            'uriTemplate': '/persoas/checkNameDuplicity',
            'method': 'GET',
            'requestParams': {
                'nomePersoa': this.context.serialize(nomePersoa, 'string'),
                'apodo': this.context.serialize(apodo, 'string')
            },
            'responseType': 'PersoaBasicDTO[]'
        });
    }

    checkNameDuplicityUrl(nomePersoa: string, apodo: string): string {
        return this.context.buildUrl('/persoas/checkNameDuplicity' , null);
    }

    create(persoaDTO: PersoaDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/persoas',
            'method': 'POST',
            'requestBody': this.context.serialize(persoaDTO, 'PersoaDTO'),
            'responseType': 'number'
        });
    }

    createUrl(persoaDTO: PersoaDTO): string {
        return this.context.buildUrl('/persoas' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/persoas/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/persoas/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: PersoaFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/persoas',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'PersoaFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: PersoaFilter, page: Pageable): string {
        return this.context.buildUrl('/persoas' , null);
    }

    findAllBasic(filter: PersoaFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/persoas/findAllBasic',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'PersoaFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicUrl(filter: PersoaFilter, page: Pageable): string {
        return this.context.buildUrl('/persoas/findAllBasic' , null);
    }

    get(id: number): Observable<PersoaDTO> {
        return this.context.request({
            'uriTemplate': '/persoas/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'PersoaDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/persoas/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getPublic(id: number): Observable<PublicPersoaDTO> {
        return this.context.request({
            'uriTemplate': '/persoas/{id}/getPublic',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'PublicPersoaDTO'
        });
    }

    getPublicUrl(id: number): string {
        return this.context.buildUrl('/persoas/{id}/getPublic' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, persoaDTO: PersoaDTO): Observable<PersoaDTO> {
        return this.context.request({
            'uriTemplate': '/persoas/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(persoaDTO, 'PersoaDTO'),
            'responseType': 'PersoaDTO'
        });
    }

    updateUrl(id: number, persoaDTO: PersoaDTO): string {
        return this.context.buildUrl('/persoas/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class ProducionResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(producionDTO: ProducionDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/producions',
            'method': 'POST',
            'requestBody': this.context.serialize(producionDTO, 'ProducionDTO'),
            'responseType': 'number'
        });
    }

    createUrl(producionDTO: ProducionDTO): string {
        return this.context.buildUrl('/producions' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/producions/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/producions/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: ProducionFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/producions',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'ProducionFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: ProducionFilter, page: Pageable): string {
        return this.context.buildUrl('/producions' , null);
    }

    findAllBasic(filter: ProducionFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/producions/findAllBasic',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'ProducionFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicUrl(filter: ProducionFilter, page: Pageable): string {
        return this.context.buildUrl('/producions/findAllBasic' , null);
    }

    get(id: number): Observable<ProducionDTO> {
        return this.context.request({
            'uriTemplate': '/producions/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'ProducionDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/producions/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getPublic(id: number): Observable<ProducionPublicDTO> {
        return this.context.request({
            'uriTemplate': '/producions/public/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'ProducionPublicDTO'
        });
    }

    getPublicUrl(id: number): string {
        return this.context.buildUrl('/producions/public/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, producionDTO: ProducionDTO): Observable<ProducionDTO> {
        return this.context.request({
            'uriTemplate': '/producions/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(producionDTO, 'ProducionDTO'),
            'responseType': 'ProducionDTO'
        });
    }

    updateUrl(id: number, producionDTO: ProducionDTO): string {
        return this.context.buildUrl('/producions/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class PublicacionResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(publicacionDTO: PublicacionDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/publicacions',
            'method': 'POST',
            'requestBody': this.context.serialize(publicacionDTO, 'PublicacionDTO'),
            'responseType': 'number'
        });
    }

    createUrl(publicacionDTO: PublicacionDTO): string {
        return this.context.buildUrl('/publicacions' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/publicacions/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/publicacions/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: PublicacionFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/publicacions',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'PublicacionFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: PublicacionFilter, page: Pageable): string {
        return this.context.buildUrl('/publicacions' , null);
    }

    findAllBasic(filter: PublicacionFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/publicacions/findAllBasic',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'PublicacionFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllBasicUrl(filter: PublicacionFilter, page: Pageable): string {
        return this.context.buildUrl('/publicacions/findAllBasic' , null);
    }

    get(id: number): Observable<PublicacionDTO> {
        return this.context.request({
            'uriTemplate': '/publicacions/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'PublicacionDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/publicacions/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getPublic(id: number): Observable<PublicacionPublicDTO> {
        return this.context.request({
            'uriTemplate': '/publicacions/public/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'PublicacionPublicDTO'
        });
    }

    getPublicUrl(id: number): string {
        return this.context.buildUrl('/publicacions/public/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, publicacionDTO: PublicacionDTO): Observable<PublicacionDTO> {
        return this.context.request({
            'uriTemplate': '/publicacions/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(publicacionDTO, 'PublicacionDTO'),
            'responseType': 'PublicacionDTO'
        });
    }

    updateUrl(id: number, publicacionDTO: PublicacionDTO): string {
        return this.context.buildUrl('/publicacions/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class StaticI18nResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    getByName(name: string): Observable<StaticI18nDTO> {
        return this.context.request({
            'uriTemplate': '/static-i18n/name/{name}',
            'method': 'GET',
            'pathVariables': {
                'name': this.context.serialize(name, 'string')
            },
            'responseType': 'StaticI18nDTO'
        });
    }

    getByNameUrl(name: string): string {
        return this.context.buildUrl('/static-i18n/name/{name}' , {
            'name': this.context.serialize(name, 'string')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class StaticResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(estaticaDTO: StaticDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/static',
            'method': 'POST',
            'requestBody': this.context.serialize(estaticaDTO, 'StaticDTO'),
            'responseType': 'number'
        });
    }

    createUrl(estaticaDTO: StaticDTO): string {
        return this.context.buildUrl('/static' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/static/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/static/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filter: StaticFilter, page: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/static',
            'method': 'GET',
            'requestParams': {
                'filter': this.context.serialize(filter, 'StaticFilter'),
                'page': this.context.serialize(page, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filter: StaticFilter, page: Pageable): string {
        return this.context.buildUrl('/static' , null);
    }

    get(id: number): Observable<StaticDTO> {
        return this.context.request({
            'uriTemplate': '/static/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'StaticDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/static/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getByName(name: string): Observable<StaticDTO> {
        return this.context.request({
            'uriTemplate': '/static/name/{name}',
            'method': 'GET',
            'pathVariables': {
                'name': this.context.serialize(name, 'string')
            },
            'responseType': 'StaticDTO'
        });
    }

    getByNameUrl(name: string): string {
        return this.context.buildUrl('/static/name/{name}' , {
            'name': this.context.serialize(name, 'string')
        });
    }

    update(id: number, estaticaDTO: StaticDTO): Observable<StaticDTO> {
        return this.context.request({
            'uriTemplate': '/static/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(estaticaDTO, 'StaticDTO'),
            'responseType': 'StaticDTO'
        });
    }

    updateUrl(id: number, estaticaDTO: StaticDTO): string {
        return this.context.buildUrl('/static/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class UserResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(userFormDTO: UserFormDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/users',
            'method': 'POST',
            'requestBody': this.context.serialize(userFormDTO, 'UserFormDTO'),
            'responseType': 'number'
        });
    }

    createUrl(userFormDTO: UserFormDTO): string {
        return this.context.buildUrl('/users' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filtro: UserFilter, pageable: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/users',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'UserFilter'),
                'pageable': this.context.serialize(pageable, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filtro: UserFilter, pageable: Pageable): string {
        return this.context.buildUrl('/users' , null);
    }

    findAllOrdenadosPorLogin(): Observable<UserBasicDTO[]> {
        return this.context.request({
            'uriTemplate': '/users/allOrderByLogin',
            'method': 'GET',
            'responseType': 'UserBasicDTO[]'
        });
    }

    findAllOrdenadosPorLoginUrl(): string {
        return this.context.buildUrl('/users/allOrderByLogin' , null);
    }

    get(id: number): Observable<UserFormDTO> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'UserFormDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getAuthorities(): Observable<string[]> {
        return this.context.request({
            'uriTemplate': '/users/authorities',
            'method': 'GET',
            'responseType': 'string[]'
        });
    }

    getAuthoritiesUrl(): string {
        return this.context.buildUrl('/users/authorities' , null);
    }

    getAvatar(login: string, avatarId: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{login}/{avatarId}/avatar.jpg',
            'method': 'GET',
            'pathVariables': {
                'login': this.context.serialize(login, 'string'),
                'avatarId': this.context.serialize(avatarId, 'string')
            }
        });
    }

    getAvatarUrl(login: string, avatarId: string): string {
        return this.context.buildUrl('/users/{login}/{avatarId}/avatar.jpg' , {
            'login': this.context.serialize(login, 'string'),
            'avatarId': this.context.serialize(avatarId, 'string')
        });
    }

    update(id: number, userFormDTO: UserFormDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(userFormDTO, 'UserFormDTO')
        });
    }

    updateUrl(id: number, userFormDTO: UserFormDTO): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}


export function apinaConfigFactory() {
    return new ApinaConfig();
}

@NgModule({
    imports: [HttpClientModule],
    providers: [
        { provide: ApinaEndpointContext, useClass: DefaultApinaEndpointContext },
        { provide: ApinaConfig, useFactory: apinaConfigFactory }
    ]
})
export class ApinaModule {}
